<template>
  <topupwd />
</template>

<script>
import topupwd from './component/topupwd.vue'

export default {
  components: {
    topupwd
  },
  data () {
    return {
      webname: process.env.VUE_APP_TITLE,
      userInfo: {}
    }
  },
  computed: {
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    }
  }
}
</script>

<style lang="scss">
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}
</style>
